<template>
  <div class="paddingTopPage centre">
    <ToolbarTop url="/" backLabel="Home"></ToolbarTop>
    <div v-if="!this.$store.state.fratiOk || !this.$store.state.paramsOk">
      <img src="@/assets/img/wheel.gif">
    </div>
    <div class="mb20" v-if="this.$store.state.fratiOk && this.$store.state.paramsOk">
      <div class="title" v-html="this.$store.state.params.annuarioFrati"></div>

      <div class="input-container">
        <input 
          @keyup="filter"
          v-model="searchInput" 
          type="text" 
          placeholder=" Suchen..." 
          class="filter-input">
      </div>

      <!-- Ogni frate -->
      <div :id="'scheda-' + index" v-for="(item, index) in this.$store.state.frati" :key="index" >
        
        <div v-if="item.status != 'Q'">
          <div class="card flex align-items-center line-height-0">
            <router-link :to="{ name: 'Bruder',  params: { bruder: nameAndSurname(item.name, item.vorname) } }">
              <div><img :src="foto(item.foto)" width="75" height="75" /></div>
            </router-link>
            <div class="cardTextBlock flex justify-content-left flex-direction-column">

              <router-link class="font20 jauneclair bold" :to="{ name: 'Bruder',  params: { bruder: nameAndSurname(item.name, item.vorname) } }">
                <div>{{ afficheVornameName(item.vorname, item.name) }}</div>
              </router-link>

              <a class="testoNormale blanc" :href="emailAdr(item.emailadresse)" target="new">{{ item.emailadresse }}</a>
              <a class="testoNormale blanc" :href="numTel(item.telefon)" target="new">{{ item.telefon }}</a>
            </div>
            <!--<span class="cardFunzione" v-if="item[0].funzione"> ({{ item[0].funzione }})</span>-->
          </div>
        </div>
          
      </div>
    </div>
  </div>
</template>

<script>
import ToolbarTop from './ToolbarTop.vue';
import { mapState } from 'vuex';

export default {
  name: 'VerzeichnisBruder',
  components: { ToolbarTop },
  data() {
    return {
      titolo: "",
      localName: "",
      list: [],
      search: null,
      searchInput: "",
    }
  },
  computed: {
    ...mapState(['frati', 'fratiOk', 'params', 'paramsOk']),
  },
  watch: {
    paramsOk() {
      this.titolo = this.$store.state.params.appNameDE
      console.log('titolo = ', this.titlo )
    },
    fratiOk() {
      console.log('fratiok...')
      this.$store.state.frati.sort(function (a, b) {
        if ( a.vorname < b.vorname ) { return -1; }
        if ( a.vorname > b.vorname ) { return 1; }
        return 0;
      })

      this.$store.state.frati.forEach( (item) => {
        this.list.push(item.vorname.trim() + " " + item.name.trim());
      })

      console.log('list après assegnamento = ', this.list)

    }
  },
  beforeCreate() {
    // verify that the user is logged
    this.localName     = localStorage.getItem("nameField");
    if ( this.localName == null ) {
      this.$router.push({ path: '/', query: { callback: 'verzeichnis' } })
    }
  },
  created() {
    // Load params
    if ( !this.$store.state.paramsOk ) {
      this.$store.dispatch("getParams"); }
    else {
      this.titolo = this.$store.state.params.appNameDE
    }
    // Load Frati
    if ( !this.$store.state.fratiOk ) {
      this.$store.dispatch("getFrati");
    }
  },

  updated() { 
//    this.$nextTick(() => {
//        console.log('la liste dans nexttick = ', this.list)
//        //var theSearch = document.querySelector(".filter-input");
//        //console.log('filter-input ', this.search)
//        //theSearch.addEventListener("input", this.filter);
//        this.filter()
//      });
  },
  methods: {
    filter(e) {
      console.log('target = ', e.target.value)
      //console.log('Liste durant le filtrage = ', this.list)

      var result  = this.list.filter( (item) => item.toLowerCase().includes(e.target.value.toLowerCase()));

      console.log('array 1 ', result)
      console.log('array 2 ', this.list)

      this.list.forEach( ( frate, index ) => {
        if ( result.includes( frate ) ) {
          document.getElementById( 'scheda-' + index ).style.display = "block";
          console.log('frate trouve = ', frate, ' numero = ', index )
        } else {
          document.getElementById( 'scheda-' + index ).style.display = "none";
          console.log('frate qui est pas trouvé = ', frate, ' numero = ', index )
        }
      })

    },

    afficheVornameName(vorname, name) {
      return vorname + " " + name.toUpperCase();
    },
    nameAndSurname(nom, prenom) {
      return nom.trim() + "-" + prenom.trim();
    },
    foto(fratefoto) {
      return this.$store.state.rootUrl + this.$store.state.imgUrl + fratefoto;
    },
    emailAdr(email) {
      return "mailto:" + email;
    },
    numTel(tel) {
      return "tel:" + tel;
    }
  }
} 
</script>